import BasicTemplate from "@templates/BasicTemplate";
import React, { FC } from "react";
import Hero from "@components/molecules/Hero/Hero";
import WhatWeDo from "@components/molecules/WhatWeDo/WhatWeDo";
import AboutUs from "@components/molecules/AboutUs/AboutUs";
import WhatMakesUsDifferent from "@components/molecules/WhatMakesUsDifferent/WhatMakesUsDifferent";
import OnSale from "@components/organisms/OnSale/OnSale";
import OtherInvestments from "@components/organisms/OtherInvestments/OtherInvestments";
import Contact from "@components/molecules/Contact/Contact";
import { graphql } from "gatsby";

const Index: FC<Props> = ({
  data: {
    directus: { settings },
  },
}) => (
  <BasicTemplate
    renderHero={Hero}
    title={settings.website_title}
    description={settings.website_description}
    ogImage={settings.website_image.imageFile.childImageSharp.fixed.src}
  >
    <WhatWeDo />
    <AboutUs />
    <WhatMakesUsDifferent />
    <OnSale />
    <OtherInvestments />
    <Contact />
  </BasicTemplate>
);

interface Props {
  data: {
    directus: {
      settings: {
        website_title: string;
        website_description: string;
        website_image: {
          imageFile: {
            childImageSharp: {
              fixed: {
                src: string;
              };
            };
          };
        };
      };
    };
  };
}

export const query = graphql`
  {
    directus {
      settings {
        website_title
        website_description
        website_image {
          id
          imageFile {
            childImageSharp {
              fixed(quality: 70, toFormat: JPG) {
                src
              }
            }
          }
        }
      }
    }
  }
`;

export default Index;

import { css } from "styled-components";

export const paginationStyles = css`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-radius: 50%;
    background: ${({ theme }) => theme.foreground};
    margin-right: 20px;

    :last-child {
      margin: 0;
    }
  }

  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.primary};
  }
`;

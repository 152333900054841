import React, { useEffect } from "react";
import styled from "styled-components";
import { Headline, SubHeadline } from "@components/atoms/Headline/Headline";
import { graphql, useStaticQuery } from "gatsby";
import { Properties } from "@interfaces/properties";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import Investment from "@components/molecules/Investment/Investment";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { useLocation } from "react-use";
import { Y_OFFSET } from "@config/main";

const StyledWrapper = styled.section`
  margin: 220px auto 0;
  width: 90%;
  max-width: 1600px;

  @media (max-width: 1024px) {
    margin: 120px auto 0;
  }

  @media (max-width: 580px) {
    margin: 80px auto 0;
  }
`;

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;

  @media (max-width: 1024px) {
    margin-bottom: 100px;
  }

  @media (max-width: 580px) {
    margin-bottom: 60px;
  }
`;

const StyledHeadline = styled(Headline)`
  text-align: center;
  max-width: 560px;
`;

const StyledSubHeadline = styled(SubHeadline)`
  text-align: center;
  order: -1;
`;

const StyledInvestmentsWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledInvestment = styled(Investment)`
  margin-bottom: 120px;

  @media (min-width: 1025px) {
    padding-bottom: 60px;
    border-bottom: 1px solid ${({ theme }) => theme.washLight};
  }

  :last-child {
    @media (min-width: 1025px) {
      padding-bottom: 0;
    }
    margin-bottom: 0;
    border-bottom: 0;
  }

  @media (max-width: 1024px) {
    margin-bottom: 80px;
  }
`;

const OnSale = () => {
  const {
    directus: { investment },
  } = useStaticQuery<Query>(query);
  const { scroll } = useLocomotiveScroll();
  const { state } = useLocation();

  useEffect(() => {
    if (state?.scrollTo && scroll) {
      scroll.scrollTo(state.scrollTo, { offset: Y_OFFSET });
      window.history.replaceState(null, "");
    }
  }, [state, scroll]);

  return (
    <StyledWrapper data-scroll-section="" id="on_sale">
      <StyledHeader data-scroll="" data-scroll-speed="1">
        <StyledHeadline>Sprawdzone mieszkania dla Ciebie</StyledHeadline>
        <StyledSubHeadline>Aktualnie w sprzedaży</StyledSubHeadline>
      </StyledHeader>

      <StyledInvestmentsWrapper>
        {investment.map(
          (
            {
              id,
              name,
              slug,
              logo,
              location,
              description,
              properties,
              gallery,
            },
            index
          ) => (
            <StyledInvestment
              name={name}
              location={location}
              description={description}
              properties={properties}
              slug={slug}
              images={gallery.map(
                ({ directus_files_id: { imageFile } }) =>
                  imageFile.childImageSharp.gatsbyImageData
              )}
              reverse={(index + 1) % 2 === 0}
              key={id}
              logo={logo?.imageFile.publicURL}
            />
          )
        )}
      </StyledInvestmentsWrapper>
    </StyledWrapper>
  );
};

interface Query {
  directus: {
    investment: {
      id: string;
      name: string;
      location: string;
      description: string;
      properties?: Properties[];
      slug: string;
      gallery: {
        directus_files_id: {
          id: string;
          imageFile: ChildImageSharp;
        };
      }[];
      logo?: {
        imageFile: {
          publicURL: string;
        };
      };
    }[];
  };
}

const query = graphql`
  {
    directus {
      investment(filter: { is_other_investment: { _neq: true } }) {
        id
        name
        location
        description
        properties
        slug
        is_other_investment
        logo {
          id
          imageFile {
            publicURL
          }
        }
        gallery {
          directus_files_id {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(quality: 80)
              }
            }
          }
        }
      }
    }
  }
`;

export default OnSale;

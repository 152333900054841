import React, { FC } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import InvestmentLocation from "@components/atoms/InvestmentLocation/InvestmentLocation";
import InvestmentDescription from "@components/atoms/InvestmentDescription/InvestmentDescription";
import InvestmentLogo from "@components/atoms/InvestmentLogo/InvestmentLogo";
import { Link } from "gatsby";
import { withLineHoverEffect } from "@theme/withLineHoverEffect";

const StyledWrapper = styled.article`
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const StyledImageWrapper = styled.div`
  position: relative;
  max-width: 700px;
  width: 36.45vw;
  max-height: 480px;
  height: 25vw;
  margin-right: 90px;

  @media (max-width: 1024px) {
    width: 100%;
    height: 54vw;
    margin-right: 0;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  border-radius: 32px;
`;

const StyledContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledName = styled.h2`
  font-weight: 700;
  font-size: 4.4rem;
  margin: 0 0 25px;
  color: ${({ theme }) => theme.primary};

  a {
    color: inherit;
    text-decoration: none;

    ${({ theme }) => withLineHoverEffect(theme.primary, "4px")}
  }

  @media (max-width: 1024px) {
    width: 100%;
    text-align: center;
    margin-top: 40px;
  }

  @media (max-width: 580px) {
    font-size: 3.4rem;
  }
`;

const StyledLocation = styled(InvestmentLocation)`
  margin: 0 0 25px;
`;

const StyledDescription = styled(InvestmentDescription)``;

const OtherInvestment: FC<Props> = ({
  name,
  location,
  description,
  image,
  logo,
  slug,
}) => (
  <StyledWrapper>
    <StyledImageWrapper>
      <StyledImage image={getImage(image)} alt={name} />
      {logo && <InvestmentLogo src={logo} alt={name} />}
    </StyledImageWrapper>
    <StyledContentWrapper>
      <StyledName>
        <Link to={`/${slug}`}>{name}</Link>
      </StyledName>
      <StyledLocation>{location}</StyledLocation>
      <StyledDescription content={description} />
    </StyledContentWrapper>
  </StyledWrapper>
);

interface Props {
  name: string;
  location: string;
  description: string;
  image: ImageDataLike;
  logo?: string;
  slug: string;
}

export default OtherInvestment;

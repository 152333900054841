import React from "react";
import styled from "styled-components";
import { Headline, SubHeadline } from "@components/atoms/Headline/Headline";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import OtherInvestment from "@components/molecules/OtherInvestment/OtherInvestment";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { paginationStyles } from "@theme/repeatedStyles";

SwiperCore.use([Pagination]);

const StyledWrapper = styled.section`
  margin: 180px auto 0;
  width: 90%;
  max-width: 1600px;

  @media (max-width: 1024px) {
    margin: 120px auto 0;
  }

  @media (max-width: 580px) {
    margin: 80px auto 0;
  }
`;

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;

  @media (max-width: 580px) {
    margin-bottom: 50px;
  }
`;

const StyledHeadline = styled(Headline)`
  text-align: center;
  margin: 0 0 15px;
`;

const StyledSubHeadline = styled(SubHeadline)`
  text-align: center;
  order: -1;
`;

const StyledParagraph = styled.div`
  margin: 0;
  max-width: 400px;
  text-align: center;
  line-height: 1.5;
`;

const StyledSwiperWrapper = styled.div`
  overflow: hidden;

  .swiper {
    width: 100%;
    position: relative;
  }

  .swiper-wrapper {
    display: inline-flex;
    align-items: center;
  }

  @media (max-width: 1024px) {
    max-width: 700px;
    margin: auto;
  }
`;

const StyledPagination = styled.div`
  ${paginationStyles}
`;

const OtherInvestments = () => {
  const {
    directus: { investment },
  } = useStaticQuery<Query>(query);

  return (
    <StyledWrapper data-scroll-section="" id="other_investments">
      <StyledHeader data-scroll="" data-scroll-speed="1">
        <StyledHeadline>Sprawdź nasze realizacje</StyledHeadline>
        <StyledSubHeadline>Inne inwestycje</StyledSubHeadline>
        <StyledParagraph>
          Przekonaj się, jak duże jest nasze doświadczenie w budownictwie.
        </StyledParagraph>
      </StyledHeader>

      <StyledSwiperWrapper data-scroll="" data-scroll-speed="1">
        <Swiper
          slidesPerView={1}
          pagination={{ el: "#other_investments_pagination", clickable: true }}
          loop
          loopedSlides={investment.length}
          grabCursor
          updateOnWindowResize
          spaceBetween={10}
        >
          {investment.map(
            ({ gallery, location, name, description, id, slug }) => (
              <SwiperSlide key={id}>
                <OtherInvestment
                  name={name}
                  location={location}
                  description={description}
                  slug={slug}
                  image={
                    gallery[0].directus_files_id.imageFile.childImageSharp
                      .gatsbyImageData
                  }
                />
              </SwiperSlide>
            )
          )}
        </Swiper>
        <StyledPagination id="other_investments_pagination" />
      </StyledSwiperWrapper>
    </StyledWrapper>
  );
};

interface Query {
  directus: {
    investment: {
      id: string;
      name: string;
      slug: string;
      location: string;
      description: string;
      gallery: {
        directus_files_id: {
          imageFile: ChildImageSharp;
        };
      }[];
    }[];
  };
}

const query = graphql`
  {
    directus {
      investment(filter: { is_other_investment: { _eq: true } }) {
        id
        name
        description
        location
        slug
        gallery {
          directus_files_id(limit: 1) {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 80
                  placeholder: DOMINANT_COLOR
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default OtherInvestments;

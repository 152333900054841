import React, { FC } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from "@components/atoms/Button/Button";
import Dots from "@assets/svg/dots.svg";
import ScrollTo from "@components/atoms/ScrollTo/ScrollTo";
import { motion } from "framer-motion";
import { easeOutCirc } from "@theme/easings";

const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 90px;
  display: flex;
  max-width: 1920px;
  margin: auto;
  min-height: 645px;

  @media (max-width: 1024px) {
    flex-direction: column;
    position: relative;
    min-height: unset;
    width: 90%;
    height: unset;
  }
`;

const StyledLeftWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    align-items: flex-start;
    flex: unset;
  }
`;

const StyledLeftInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1024px) {
    align-items: center;
  }
`;

const StyledTitle = styled(motion.h1)`
  font-weight: 700;
  color: ${({ theme }) => theme.primary};
  font-size: 5.6rem;
  margin: 0 0 25px;
  max-width: 470px;
  text-align: center;

  @media (max-width: 580px) {
    font-size: 3.8rem;
    margin: 0 0 20px;
  }
`;

const StyledSubTitle = styled(motion.h2)`
  font-size: 2.2rem;
  font-weight: 600;
  order: -1;
  margin: 0 0 10px;
  text-align: center;

  @media (max-width: 1024px) {
    margin: 60px 0 10px;
  }

  @media (max-width: 580px) {
    font-size: 1.8rem;
  }
`;

const StyledParagraph = styled(motion.p)`
  line-height: 1.6;
  max-width: 470px;
  margin: 0 0 25px;

  @media (max-width: 580px) {
    font-size: 1.4rem;
    text-align: center;
    margin: 0 0 15px;
  }
`;

const StyledRightWrapper = styled.div`
  height: 85%;
  max-width: 1200px;
  width: 60vw;
  position: relative;

  @media (max-width: 1420px) {
    width: 50vw;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
    max-width: 700px;
    margin: 20px auto 0;
  }
`;

const StyledImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`;

const StyledDots = styled(Dots)`
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-50%, 50%);
  z-index: -1;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  text-decoration: none;

  @media (max-width: 1024px) {
    position: absolute;
    display: none;
  }
`;

const Hero: FC = () => {
  const { file } = useStaticQuery<{ file: ChildImageSharp }>(query);

  const getAnimation = (delay: number) => ({
    initial: { opacity: 0, y: "50%" },
    animate: { opacity: 1, y: 0 },
    transition: { ease: easeOutCirc, duration: 0.5, delay },
  });

  return (
    <StyledWrapper data-scroll-section="">
      <StyledLeftWrapper data-scroll="" data-scroll-speed="1">
        <StyledLeftInnerWrapper>
          <StyledTitle {...getAnimation(1.6)}>
            BGK Investment Home & Nature.
          </StyledTitle>
          <StyledSubTitle {...getAnimation(1.5)}>
            Solidny Deweloper
          </StyledSubTitle>
          <StyledParagraph {...getAnimation(1.7)}>
            Deweloper wprowadzający innowacje w pierwotny rynek nieruchomości.
          </StyledParagraph>

          <motion.div {...getAnimation(1.8)}>
            <StyledButton forwardedAs={ScrollTo} href="/#what_we_do">
              Zobacz szczegóły
            </StyledButton>
          </motion.div>
        </StyledLeftInnerWrapper>
      </StyledLeftWrapper>
      <StyledRightWrapper>
        <StyledImage
          alt="Siedziba BGK Investment"
          image={getImage(file.childImageSharp.gatsbyImageData)}
        />
        <StyledDots />
      </StyledRightWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "residence" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: FULL_WIDTH
          placeholder: DOMINANT_COLOR
        )
      }
    }
  }
`;

export default Hero;

import styled from "styled-components";

export const Headline = styled.h2`
  font-size: 4.4rem;
  font-weight: 700;
  margin: 0;
  color: ${({ theme }) => theme.primary};
  line-height: 1.4;

  @media (max-width: 580px) {
    font-size: 3.4rem;
  }
`;

export const SubHeadline = styled.h3`
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0 0 15px;

  @media (max-width: 580px) {
    font-size: 1.8rem;
  }
`;

import React, { FC } from "react";
import styled from "styled-components";
import { Headline, SubHeadline } from "@components/atoms/Headline/Headline";
import WhatWeDoItem from "@components/atoms/WhatWeDoItem/WhatWeDoItem";
import { blockIcon, builderIcon, userWithKeyIcon } from "@assets/icons";

const StyledWrapper = styled.section`
  margin: 180px auto 0;
  width: 90%;
  max-width: 1350px;

  @media (max-width: 1024px) {
    margin: 120px auto 0;
  }

  @media (max-width: 580px) {
    margin: 80px auto 0;
  }
`;

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;
`;

const StyledHeadline = styled(Headline)`
  text-align: center;
  max-width: 400px;
`;

const StyledSubHeadline = styled(SubHeadline)`
  text-align: center;
  order: -1;
`;

const StyledItemsWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledWhatWeDoItem = styled(WhatWeDoItem)`
  margin: 0 40px 0 0;

  :last-child {
    margin: 0;
  }

  @media (max-width: 1024px) {
    margin: 0 0 60px;
  }
`;

const WhatWeDo: FC = () => (
  <StyledWrapper data-scroll-section="" id="what_we_do">
    <StyledHeader data-scroll="" data-scroll-speed="1">
      <StyledHeadline>W tym jesteśmy najlepsi:</StyledHeadline>
      <StyledSubHeadline>Czym się zajmujemy?</StyledSubHeadline>
    </StyledHeader>

    <StyledItemsWrapper data-scroll="" data-scroll-speed="1">
      <StyledWhatWeDoItem
        title="Budowa"
        description="Budujemy obiekty mieszkalne wielorodzinne, przemysłowe i inwestycyjne."
        icon={builderIcon}
      />
      <StyledWhatWeDoItem
        title="Handel"
        description="Kupujemy, budujemy, remontujemy i sprzedajemy nieruchomości."
        icon={userWithKeyIcon}
      />
      <StyledWhatWeDoItem
        title="Wynajem"
        description="Wynajmujemy maszyny i  urządzenia budowlane oraz pomieszczenia magazynowe."
        icon={blockIcon}
      />
    </StyledItemsWrapper>
  </StyledWrapper>
);

export default WhatWeDo;

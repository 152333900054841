import { PropertyIcon } from "@interfaces/properties";
import {
  buildingsIcon,
  segmentsIcon,
  tiersIcon,
  venuesIcon,
} from "@assets/icons";

export const getPropertyIcon = (icon: PropertyIcon) => {
  switch (icon) {
    case "buildings":
      return buildingsIcon;
    case "segments":
      return segmentsIcon;
    case "tiers":
      return tiersIcon;
    case "venues":
      return venuesIcon;
    default:
      return buildingsIcon;
  }
};

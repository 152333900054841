import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import AnimatedImage from "@components/atoms/AnimatedImage/AnimatedImage";
import { getImage } from "gatsby-plugin-image";
import SecondaryDots from "@assets/svg/dots_secondary.svg";
import Attribute from "@components/atoms/Attribute/Attribute";
import {
  drawingCompassIcon,
  forkLiftIcon,
  groupTaskIcon,
  medalIcon,
} from "@assets/icons";

const StyledWrapper = styled.section`
  background: ${({ theme }) => theme.backgroundSecondary};
  padding: 200px 0 120px;
  margin: 80px auto 0;

  @media (max-width: 1024px) {
    padding: 80px 0 120px;
  }

  @media (max-width: 580px) {
    padding: 80px 0;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  max-width: 1680px;

  @media (min-width: 1420px) {
    padding-right: 80px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    max-width: 700px;
  }
`;

const StyledRightWrapper = styled.div`
  position: relative;
  max-width: 800px;
  width: 41.6666667vw;
  max-height: 680px;
  height: 35.4166667vw;
  margin-left: 80px;

  @media (max-width: 1420px) {
    width: 38vw;
    height: 30vw;
  }

  @media (max-width: 1180px) {
    display: none;
  }

  @media (max-width: 1024px) {
    display: block;
    width: 100%;
    max-width: unset;
    max-height: unset;
    height: auto;
    margin: 40px 0 0;
  }
`;

const StyledImage = styled(AnimatedImage)`
  width: 100%;
  height: 100%;
  border-radius: 32px;
  overflow: hidden;
`;

const StyledSecondaryDots = styled(SecondaryDots)`
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  display: none;

  @media (min-width: 1420px) {
    display: block;
  }
`;

const StyledLeftWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 1180px) {
    max-width: 800px;
  }
`;

const StyledHeader = styled.header`
  margin: 0 0 25px;

  h2 {
    color: ${({ theme }) => theme.primary};
    font-weight: 700;
    font-size: 4.4rem;
    margin: 0;
  }

  @media (max-width: 580px) {
    h2 {
      font-size: 3.4rem;
    }
  }
`;

const StyledParagraph = styled.p`
  line-height: 1.6;
  max-width: 650px;
  margin: 0;

  span {
    color: ${({ theme }) => theme.primary};
    font-weight: 700;
  }
`;

const StyledAttributesWrapper = styled.div`
  margin-top: 65px;
`;

const StyledAttributesRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 40px;

  :first-of-type {
    margin-bottom: 70px;
  }

  @media (max-width: 1024px) {
    padding-left: 0;
  }

  @media (max-width: 580px) {
    flex-direction: column;
    align-items: center;

    :first-of-type {
      margin-bottom: 40px;
    }
  }
`;

const StyledAttribute = styled(Attribute)`
  :first-of-type {
    margin-right: 40px;
  }

  @media (max-width: 580px) {
    :first-of-type {
      margin-right: 0;
      margin-bottom: 40px;
    }
  }
`;

const WhatMakesUsDifferent = () => {
  const { file } = useStaticQuery<{ file: ChildImageSharp }>(query);
  const { backgroundSecondary } = useContext(ThemeContext);

  return (
    <StyledWrapper data-scroll-section="">
      <StyledInnerWrapper>
        <StyledLeftWrapper data-scroll="" data-scroll-speed="2">
          <StyledHeader>
            <h2>Co nas wyróżnia?</h2>
          </StyledHeader>
          <StyledParagraph>
            Firma BGK Investment została stworzona na fundamencie{" "}
            <span>20 letniego doświadczenia</span> w zakresie budowy budynków
            wielorodzinnych, przemysłowych, budynków użyteczności publicznej i
            komercyjnej.
            <br /> Mamy również duże doświadczenie w produkcji i obróbce drewna
            oraz obsłudze stacji kontroli pojazdów.
          </StyledParagraph>

          <StyledAttributesWrapper>
            <StyledAttributesRow>
              <StyledAttribute
                icon={forkLiftIcon}
                title="Wyposażenie"
                description="Własny park maszyn i urządzeń budowlanych. (szalunki , rusztowania , doki, samochody dostawcze  z HDS, wózki widłowe i ładowarki teleskopowe)."
              />

              <StyledAttribute
                icon={medalIcon}
                title="Solidne zasoby"
                description="Własna kadra wykwalifikowanych pracowników."
              />
            </StyledAttributesRow>
            <StyledAttributesRow>
              <StyledAttribute
                icon={groupTaskIcon}
                title="Doświadczenie"
                description="Dysponujemy szeroką wiedzą i doświadczeniem w zakresie  nowoczesnego i ekologicznego budownictwa."
              />
              <StyledAttribute
                icon={drawingCompassIcon}
                title="Innowacje"
                description="Oferujemy nową technologię budowy w połączeniu  z nowoczesnymi systemami grzewczymi i wentylacyjnymi (pompy ciepła  oraz rekuperacja)."
              />
            </StyledAttributesRow>
          </StyledAttributesWrapper>
        </StyledLeftWrapper>

        <StyledRightWrapper data-scroll="" data-scroll-speed="1">
          <StyledImage
            image={getImage(file.childImageSharp.gatsbyImageData)}
            alt="Co nas wyróżnia?"
            background={backgroundSecondary}
          />
          <StyledSecondaryDots />
        </StyledRightWrapper>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "what_makes_us_different" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 90
          placeholder: DOMINANT_COLOR
          layout: FULL_WIDTH
        )
      }
    }
  }
`;

export default WhatMakesUsDifferent;

import React, { FC } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  line-height: 1.5;

  p {
    margin: 0 0 25px;

    :last-child {
      margin: 0;
    }
  }
  
  a {
    color: ${({theme}) => theme.primary};
    text-decoration: underline;
  }
`;

const InvestmentLocation: FC<Props> = ({ content, className }) => (
  <StyledWrapper
    dangerouslySetInnerHTML={{ __html: content }}
    className={className}
  />
);

interface Props {
  content: string;
  className?: string;
}

export default InvestmentLocation;

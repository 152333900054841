import React, { FC } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import Dots from "@assets/svg/dots.svg";
import SecondaryDots from "@assets/svg/dots_secondary.svg";
import { Properties } from "@interfaces/properties";
import Button from "@components/atoms/Button/Button";
import { Link } from "gatsby";
import { Icon } from "@iconify/react";
import { getPropertyIcon } from "@utils/getPropertyIcon";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import InvestmentLocation from "@components/atoms/InvestmentLocation/InvestmentLocation";
import InvestmentDescription from "@components/atoms/InvestmentDescription/InvestmentDescription";
import InvestmentLogo from "@components/atoms/InvestmentLogo/InvestmentLogo";
import { paginationStyles } from "@theme/repeatedStyles";
import { withLineHoverEffect } from "@theme/withLineHoverEffect";

SwiperCore.use([Pagination]);

const StyledWrapper = styled.article<{ $reverse: boolean }>`
  display: flex;
  align-items: center;

  ${({ $reverse }) =>
    $reverse &&
    css`
      flex-direction: row-reverse;
    `};

  @media (max-width: 1420px) {
    align-items: flex-start;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    max-width: 700px;
    width: 100%;
    padding-bottom: 90px !important;
    position: relative;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
`;

const StyledImagesWrapper = styled.div<{ $reverse: boolean }>`
  margin-left: 120px;
  position: relative;

  @media (max-width: 1420px) {
    margin-left: 70px;
  }

  ${({ $reverse }) =>
    $reverse &&
    css`
      margin-left: 0;
      margin-right: 120px;

      @media (max-width: 1420px) {
        margin-right: 70px;
      }
    `};

  @media (max-width: 1024px) {
    margin: 0;
    width: 100%;
  }
`;

const StyledSwiperWrapper = styled.div`
  .swiper {
    max-width: 920px;
    width: 48vw;
    max-height: 620px;
    height: 32vw;
    border-radius: 32px;
    overflow: hidden;
    position: relative;

    @media (max-width: 1024px) {
      width: 100%;
      height: 54vw;
      max-height: 378px;
      max-width: unset;
    }
  }

  .swiper-wrapper {
    display: inline-flex;
    align-items: center;
  }

  .swiper-pagination-bullets {
    ${paginationStyles}
  }
`;

const StyledImage = styled(GatsbyImage)`
  max-width: 920px;
  width: 48vw;
  max-height: 620px;
  height: 32vw;

  @media (max-width: 1024px) {
    width: 100%;
    height: 54vw;
    max-width: unset;
    max-height: 378px;
  }
`;

const StyledHeadline = styled.h2`
  font-weight: 700;
  color: ${({ theme }) => theme.primary};
  font-size: 4.4rem;
  margin: 0 0 25px;

  a {
    color: inherit;
    text-decoration: none;

    ${({ theme }) => withLineHoverEffect(theme.primary, "4px")}
  }

  @media (max-width: 1024px) {
    text-align: center;
    width: 100%;
    font-size: 3.8rem;
  }

  @media (max-width: 580px) {
    font-size: 2.8rem;
    margin: 0 0 20px;
  }
`;

const StyledInvestmentLocation = styled(InvestmentLocation)`
  margin: 0 0 25px;
`;

const StyledDescription = styled(InvestmentDescription)`
  margin-bottom: 50px;

  @media (max-width: 1420px) {
    margin-bottom: 30px;
  }
`;

const StyledPropertiesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: 1420px) {
    flex-direction: column;
  }

  @media (max-width: 1024px) {
    flex-direction: row;
    max-width: 80%;
    flex-wrap: wrap;
  }

  @media (max-width: 760px) {
    max-width: unset;
  }

  @media (max-width: 580px) {
    justify-content: space-around;
    width: calc(100% - 20px);
    margin: 0 auto 30px;
  }
`;

const StyledProperty = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  margin: 0 20px 0 0;

  :last-of-type {
    margin: 0;
  }

  @media (max-width: 1420px) {
    margin: 0 0 10px;
  }

  @media (max-width: 580px) {
    flex-direction: column;
    margin: 0 20px 20px 0;
  }
`;

const StyledPropertyIconWrapper = styled.div`
  width: 50px;
  height: 50px;
  font-size: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.foreground};
  margin-right: 10px;
  flex-shrink: 0;

  @media (max-width: 580px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const StyledButton = styled(Button)`
  text-decoration: none;

  @media (max-width: 1024px) {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    white-space: nowrap;
  }
`;

const dotsStyles = css`
  position: absolute;
  z-index: -1;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledDots = styled(Dots)`
  ${dotsStyles};
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
`;

const StyledSecondaryDots = styled(SecondaryDots)`
  ${dotsStyles};
  bottom: 0;
  left: 0;
  transform: translate(-30%, 0%);
`;

const Investment: FC<Props> = ({
  name,
  location,
  description,
  properties,
  images,
  reverse,
  slug,
  className,
  logo,
}) => {
  const paginationId = `${slug}-pagination-wrapper`;

  return (
    <StyledWrapper
      className={className}
      $reverse={reverse}
      data-scroll=""
      data-scroll-speed="1"
    >
      <StyledContentWrapper>
        <StyledHeadline>
          <Link to={`/${slug}`}>{name}</Link>
        </StyledHeadline>
        <StyledInvestmentLocation>{location}</StyledInvestmentLocation>
        <StyledDescription content={description} />
        {properties && (
          <StyledPropertiesWrapper>
            {properties.map(({ text, icon }) => (
              <StyledProperty key={icon}>
                <StyledPropertyIconWrapper>
                  <Icon icon={getPropertyIcon(icon)} />
                </StyledPropertyIconWrapper>
                {text}
              </StyledProperty>
            ))}
          </StyledPropertiesWrapper>
        )}
        <StyledButton forwardedAs={Link} to={`/${slug}`}>
          Zobacz szczegóły
        </StyledButton>
      </StyledContentWrapper>

      <StyledImagesWrapper $reverse={reverse}>
        <StyledSwiperWrapper>
          <Swiper
            slidesPerView={1}
            loop
            loopedSlides={images.length}
            pagination={{ clickable: true, el: `#${paginationId}` }}
            grabCursor
            updateOnWindowResize
            spaceBetween={10}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <StyledImage alt={name} image={getImage(image)} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div id={paginationId} />
        </StyledSwiperWrapper>
        {reverse ? <StyledSecondaryDots /> : <StyledDots />}
        {logo && <InvestmentLogo src={logo} alt={name} $reverse={reverse} />}
      </StyledImagesWrapper>
    </StyledWrapper>
  );
};

interface Props {
  name: string;
  location: string;
  description: string;
  properties: Properties[];
  images: ImageDataLike[];
  reverse?: boolean;
  slug: string;
  logo?: string;
  className?: string;
}

export default Investment;

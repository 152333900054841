import React, { FC } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import { cssEaseOutExpo } from "@theme/easings";

const StyledWrapper = styled.div`
  width: 420px;
  height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: background 0.5s ${cssEaseOutExpo}, color 0.5s ${cssEaseOutExpo};

  :hover {
    background: ${({ theme }) => theme.foreground};
    color: ${({ theme }) => theme.background};
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: unset;

    :hover {
      background: transparent;
      color: inherit;
    }
  }
`;

const StyledIconWrapper = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.foreground};
  border-radius: 50%;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.primary};
  font-size: 4rem;
  transition: background 0.5s ${cssEaseOutExpo}, color 0.5s ${cssEaseOutExpo};

  @media (min-width: 1025px) {
    ${StyledWrapper}:hover & {
      background: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.foreground};
    }
  }

  @media (max-width: 580px) {
    width: 60px;
    height: 60px;
    font-size: 3rem;
    margin-bottom: 20px;
  }
`;

const StyledTitle = styled.h4`
  margin: 0 0 30px;
  font-weight: 700;
  font-size: 2.4rem;
  text-align: center;

  @media (max-width: 1024px) {
    margin: 0 0 20px;
  }
`;

const StyledDescription = styled.p`
  line-height: 1.6;
  text-align: center;
  max-width: 320px;
  margin: 0;
`;

const WhatWeDoItem: FC<Props> = ({ icon, title, description, className }) => (
  <StyledWrapper className={className}>
    <StyledIconWrapper>
      <Icon icon={icon} />
    </StyledIconWrapper>
    <StyledTitle>{title}</StyledTitle>
    <StyledDescription>{description}</StyledDescription>
  </StyledWrapper>
);

interface Props {
  title: string;
  description: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  className?: string;
}

export default WhatWeDoItem;

import styled, { css } from "styled-components";

export default styled.img<{ $reverse?: boolean }>`
  position: absolute;
  width: 90px;
  height: auto;
  left: 25px;
  top: 25px;

  @media (max-width: 580px) {
    width: 50px;
    top: 10px;
    left: 10px;
  }

  ${({ $reverse }) =>
    $reverse &&
    css`
      left: unset;
      right: 25px;

      @media (max-width: 580px) {
        left: unset;
        right: 10px;
      }
    `}
`;

import React, { FC } from "react";
import styled from "styled-components";
import AnimatedImage from "@components/atoms/AnimatedImage/AnimatedImage";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { getImage } from "gatsby-plugin-image";
import Dots from "@assets/svg/dots.svg";
import Button from "@components/atoms/Button/Button";
import { SubHeadline } from "@components/atoms/Headline/Headline";
import ScrollTo from "@components/atoms/ScrollTo/ScrollTo";

const StyledWrapper = styled.section`
  width: 90%;
  max-width: 1530px;
  margin: 220px auto 0;
  display: flex;
  align-items: center;
  padding-left: 80px;

  @media (max-width: 1420px) {
    align-items: flex-start;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0;
    max-width: 700px;
    margin: 120px auto 0;
  }

  @media (max-width: 580px) {
    margin: 80px auto 0;
  }
`;

const StyledLeftWrapper = styled.div`
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledImage = styled(AnimatedImage)`
  max-width: 680px;
  width: 35.4166667vw;
  max-height: 590px;
  height: 30.7291667vw;
  border-radius: 32px;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
    max-width: unset;
    max-height: unset;
  }
`;

const StyledDots = styled(Dots)`
  left: 0;
  top: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: -1;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledRightWrapper = styled.div`
  margin-left: 80px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1024px) {
    margin: 60px 0 0;
    align-items: center;
  }
`;

const StyledHeadline = styled.h2`
  margin: 0 0 25px;
  font-size: 2.8rem;

  span {
    color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 1024px) {
    text-align: center;
  }
`;

const StyledSubHeadline = styled(SubHeadline)`
  order: -1;
`;

const StyledParagraph = styled.p`
  line-height: 1.6;
  margin: 0 0 30px;

  :last-of-type {
    margin-bottom: 50px;
  }

  span {
    color: ${({ theme }) => theme.primary};
    font-weight: 500;
  }

  @media (max-width: 580px) {
    text-align: justify;
  }
`;

const StyledButton = styled(Button)`
  text-decoration: none;
`;

const AboutUs: FC = () => {
  const { file } = useStaticQuery<{ file: ChildImageSharp }>(query);

  return (
    <StyledWrapper data-scroll-section="" id="about_us">
      <StyledLeftWrapper data-scroll="" data-scroll-speed="2">
        <StyledImage
          image={getImage(file.childImageSharp.gatsbyImageData)}
          alt="BGK Investment"
        />
        <StyledDots />
      </StyledLeftWrapper>
      <StyledRightWrapper data-scroll="" data-scroll-speed="1">
        <StyledHeadline>
          Solidny i sprawdzony deweloper - <span>BGK Investment</span>
        </StyledHeadline>
        <StyledSubHeadline>O nas</StyledSubHeadline>
        <StyledParagraph>
          <span>BGK Investment</span> jest niezawodnym partnerem dla wszystkich
          szukających własnego mieszkania. Niezależnie od wielkości projektu i
          jego zakresu. Wykonujemy nasze prace zgodnie z obietnicami i
          założeniami, przestrzegając terminów i zawsze spełniając{" "}
          <span>standardy jakości</span>.
        </StyledParagraph>
        <StyledParagraph>
          W <span>branży budowlanej</span> od wielu lat pracujemy na rzecz
          rozwoju i tworzenia lepszej przyszłości dla lokalnych mieszkańców.
          Zrealizowaliśmy szeroką gamę projektów od małych osiedli
          mieszkaniowych po duże kompleksy mieszkaniowe oraz użyteczności
          publicznej zarówno w <span>Mławie</span> jak i innych miastach{" "}
          <span>w Polsce</span>.
        </StyledParagraph>
        <StyledParagraph>
          Dlatego jeśli potrzebujesz wysokiej jakości domu w przystępnej cenie,{" "}
          <u>skontaktuj się z nami</u>!
        </StyledParagraph>
        <StyledButton forwardedAs={ScrollTo} href="/#contact">
          Kontakt
        </StyledButton>
      </StyledRightWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "about_us" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: FULL_WIDTH
          placeholder: DOMINANT_COLOR
        )
      }
    }
  }
`;

export default AboutUs;

import React, { FC } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import { markerIcon } from "@assets/icons";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;

  svg {
    font-size: 3.5rem;
    margin-right: 10px;
  }
`;

const InvestmentLocation: FC<Props> = ({ className, children }) => (
  <StyledWrapper className={className}>
    <Icon icon={markerIcon} />
    {children}
  </StyledWrapper>
);

interface Props {
  className?: string;
}

export default InvestmentLocation;

import React, { FC } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import { rgba } from "polished";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 580px) {
    align-items: center;
  }
`;

const StyledIconWrapper = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: 25px;
  position: relative;

  @media (max-width: 580px) {
    width: 60px;
    height: 60px;
  }
`;

const StyledIconInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  color: ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.foreground};
  border-radius: 50%;

  @media (max-width: 580px) {
    font-size: 3rem;
  }
`;

const StyledBox = styled.span`
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 15px;
  display: block;
  background: ${({ theme }) => rgba(theme.primary, 0.2)};
  left: 0;
  top: 0;
  z-index: -1;
  transform: translate(-60%, -30%) rotate(-45deg);
`;

const StyledTitle = styled.h4`
  font-size: 2.4rem;
  font-weight: 700;
  margin: 0 0 10px;
`;

const StyledDescription = styled.p`
  max-width: 320px;
  line-height: 1.6;

  @media (max-width: 580px) {
    text-align: center;
  }
`;

const Attribute: FC<Props> = ({ icon, title, description, className }) => (
  <StyledWrapper className={className}>
    <StyledIconWrapper>
      <StyledIconInnerWrapper>
        <Icon icon={icon} />
      </StyledIconInnerWrapper>
      <StyledBox />
    </StyledIconWrapper>
    <StyledTitle>{title}</StyledTitle>
    <StyledDescription>{description}</StyledDescription>
  </StyledWrapper>
);

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  title: string;
  description: string;
  className?: string;
}

export default Attribute;
